/**
 /**
 *-1: unstarted
 * 0: ended
 * 1: playing
 * 2: paused
 * 3: buffering
 * 5: video cued
 */

import './Full.css';
import {memo, useState} from "react";
import {useSequence} from "./Sequence";
import SelectReaction from "./SelectReaction";
import {Box, Button, Stack, Toolbar} from "@mui/material";
import {deflate, getCanonicalUrlForHash, isActive, newSource} from "./util";
import SourceControls from "./SourceControls";
import copy from "copy-to-clipboard";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import Feedback from "./Feedback";
import {LoadingButton} from "@mui/lab";
import {save} from "./api";
import SaveIcon from "@mui/icons-material/Save";
import {editHash} from "./history";
import {useRenderCounter} from "./useRenderCounter";
import Player from "./Player";


const COMMAND_REDUCER = (state, command, i, arr) => {
  switch (command.type) {
    case 'play':
    case 'resume':
      // seek
      if ("seek" in command) {
        state.ts = command.ts;
        state.seek = command.seek;
      }
      // resume
      if (!state.play) {
        state.ts = command.ts;
        state.play = 1;
      }
      break;
    case 'pause':
      if (state.play) {
        state.seek = state.seek + command.ts - state.ts;
        state.ts = command.ts;
        state.play = 0;
      }
      break;
    case 'seek':
      state.ts = command.ts;
      state.seek = command.seek;
      break;
    case 'show':
      state.show = 1;
      break;
    case 'hide':
      state.show = 0;
      break;
    case 'move':
      state.left = command.left;
      state.top = command.top;
      state.width = command.width;
      state.height = command.height;
      break;
    default:
      throw new Error();
  }
  return state;
}

const sampleSource = newSource(undefined);
delete sampleSource.videoId;

export const sourceState = (source, now) => {
  const {show, left, top, width, height} = source;
  let state = {show, left, top, width, height, ts: 0, seek: 0, play: 0};
  for (let command of source.commands) {
    const dt = now - command.ts;
    if (dt < 0) break;
    state = COMMAND_REDUCER(state, command);
  }

  if (state.play) {
    state.seek += now - state.ts;
  }
  state.ts = now;

  return state;
}

// state can be a command, or it can be a sourceState
export const applyCommand = (reactionPlayerState, player, sourceBox, state, moveSource, force) => {
  console.log('applyCommand', state, force);
  const playing = reactionPlayerState === 1;
  if (sourceBox) {
    if ('show' === state.type || state.show === 1) sourceBox.style.display = isActive(reactionPlayerState) ? 'inherit' : 'none';
    if ('hide' === state.type || state.show === 0) sourceBox.style.display = 'none';
    if ('move' === state.type) moveSource(sourceBox, state);
  }
  if (player) {
    if (state.hasOwnProperty('seek')) {
      // always seek if not playing, otherwise allow tolerance
      if ((force || Math.abs(state.seek - player.getCurrentTime()) > 0.05) && state.seek < player.getDuration()) {
        console.log('seekTo', 'force', force);
        player.seekTo(state.seek, true);
      }
    }
    if (playing) {
      if (state.play === 1 || 'play' === state.type || 'resume' === state.type) {
        player.playVideo();
      }
      if (state.play === 0 || 'pause' === state.type) {
        player.pauseVideo();
      }
    } else {
      player.pauseVideo();
    }
  }
}

const Full = () => {
  // hooks
  const renderCounter = useRenderCounter();
  const {state, reactionPlayer: player, sources} = useSequence();
  const [move, setMove] = useState(false);
  const [saving, setSaving] = useState(0);

  // computed
  const edit = true;
  const videoId = state.reaction?.videoId;

  if (!videoId) return <SelectReaction/>;

  function seekTo(ts) {
    player?.seekTo(ts);
    sources.forEach(src => {
      const source = state.sources.find(s => s.id === src.id);
      src?.player?.seekTo(sourceState(source, ts).seek);
    });
  }

  async function handleSave() {
    const s = deflate(state);

    setSaving(1);
    try {
      const response = await save(s);
      if (response.data?.id) editHash(response.data?.id);
      setSaving(0);
    } catch (e) {
      // TODO report save error
      setSaving(0);
    }
  }

  return <Box height="100vh" display="flex" flexDirection="row" alignItems="stretch">
    {/*<RenderCounter counter={renderCounter}/>*/}

    <Stack direction="column" sx={{justifyContent: 'flex-end'}} ml={2} mt={2}>
      {state.sources.map(s => <SourceControls key={s.id} reactionPlayer={player} source={s} seekTo={seekTo}
                                              sx={{flexGrow: 1}}/>)}
      <Toolbar direction="row" spacing={1} m={1} sx={{}}>
        <Feedback/>
        <Box sx={{flexGrow: 1}}></Box>
        <Button onClick={e => copy(getCanonicalUrlForHash(state.version?.id))}><ContentCopyRoundedIcon/></Button>
        <LoadingButton
          size="small"
          onClick={handleSave}
          loading={saving === 1}
          startIcon={<SaveIcon/>}
          loadingPosition="start"
          variant="contained"
        >Save</LoadingButton>
      </Toolbar>
    </Stack>
    <Player key={videoId} edit={edit} move={move}/>
  </Box>;
};

const MemoizedFull = memo(Full);

export default MemoizedFull;

