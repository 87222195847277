import {v4 as uuidv4} from 'uuid';
import {createContext, useContext, useReducer, useState} from "react";
import {inflate, isEditMode, replaceOrAppend} from "./util";
import {sourceState} from "./Full";

/**
 * Context associated with a json reaction file
 */
const SequenceContext = createContext();

export const useSequence = () => {
  const context = useContext(SequenceContext);
  if (!context) throw new Error('Sequence wrapper not found');
  return context;
}

const orderFunc = (a, b) => {
  let cmp = a.ts - b.ts;
  if (cmp) return cmp;
  return a.id - b.id;
}

const OFFSET_COMMANDS = ['play', 'pause', 'seek', 'resume'];

const Sequence = ({...props}) => {
  const testVideo = '';//https://www.youtube.com/watch?v=mIuXSP2mr-g&ab_channel=MandyCaneLane';
  const [reactionUrl, setReactionUrl] = useState(testVideo);
  const [sourceUrl, setSourceUrl] = useState(testVideo);
  const [edit, setEdit] = useState(isEditMode());

  // const initialState = newReaction();

  function findCommandAt(commands, ts) {
    let o = {};
    let foundNext = false;
    for (let c of commands) {
      if (c.ts > ts) {
        if (foundNext) {
          o.nextnext = c;
          break;
        }
        o.next = c;
        foundNext = true;
      } else {
        o.prev = o.command;
        o.command = c;
      }
    }
    return o;
  }

  function sync(state, {source: sourceId, ts, seek}) {
    console.log('sync', state);
    const source = state.sources.find(s => s.id === sourceId);
    if (!source) return state;
    let commands = [...source.commands];
    const offsetCommands = commands.filter(c => OFFSET_COMMANDS.includes(c.type));
    let {prev, command, next, nextnext} = findCommandAt(offsetCommands, ts);
    const ss = sourceState(source, ts);
    if (!command) return state;

    const diff = (ss.ts - ss.seek) - (ts - seek);
    if (diff === 0) return state; // already in sync

    let moveTs = false, moveSeek = false;
    switch (command.type) {
      case 'pause':
        return state; // playback is paused, sync has no effect
      case 'resume':
        moveTs = true;
        moveSeek = false;
        break;
      case 'play':
        moveTs = true;
        moveSeek = command.hasOwnProperty('seek');
        break;
      case 'seek':
        moveTs = true;
        moveSeek = true;
        break;
    }

    if (moveTs) {
      // move command ts
      let newts = command.ts - diff;
      if (!prev || newts >= prev.ts) {
        if (next && !next.hasOwnProperty('seek')) {
          let newnextts = next.ts - diff;

          if (!nextnext || newnextts <= nextnext.ts) {
            next.ts = newnextts;
          } else {
            moveTs = false;
          }
        }
        if (moveTs) {
          command.ts = newts;
          moveSeek = false;
        }
      } else {
        // not possible
        console.log('not possible');
        return state;
      }
    }
    if (moveSeek) {
      // TODO move seek
    }

    commands.sort(orderFunc);
    source.commands = commands;
    return {...state};
  }

  function command(state, {source: sourceId, id, ts, command: type, seek, show, left, top, width, height}) {
    console.log('command', state);
    if (!id) id = uuidv4();
    const source = state.sources.find(s => s.id === sourceId);
    if (!source) return state;
    let commands = [...source.commands];
    if (type === 'delete') {
      source.commands = commands.filter(c => c.id !== id);
      return {...state};
    }

    let command = {id, ts, type, seek, show, left, top, width, height};
    if (seek === undefined || (type !== 'play' && type !== 'seek')) delete command.seek; // only seek for play and seek
    if (type === 'resume') command.type = 'play';

    replaceOrAppend(commands, c => c.id === id, command);

    commands.sort(orderFunc);
    source.commands = commands;
    return {...state};
  }

  function move(state, action) {
    const source = state.sources.find(s => s.id === action.source);
    if (!source) return state;

    const move = source.commands.filter(c => c.ts <= action.ts).reverse().find(c => c.type === 'move') || source;
    move.left = action.left;
    move.top = action.top;
    move.width = action.width;
    move.height = action.height;

    return {...state};
  }

  function reducer(state, action) {
    switch (action.type) {
      case 'setId':
        if (!action.id) return state;
        return {...state, version: {id: action.id}};
      case 'load':
        return inflate(action.load);
      case 'source':
        return {...state, source: {...state.source, ...action.source}};
      case 'move':
        return move(state, action);
      case 'command':
        return command(state, action);
      case 'sync':
        return sync(state, action);
      case 'setSource':
        const id = action.source?.id;
        if (!id) return state;
        const source = state.sources[id] || {};
        state.sources[id] = {...source, ...action.source};
        return {...state};
      default:
        throw new Error(`unknown type ${action.type} ${JSON.stringify(action)}`);
    }
  };

  const [state, dispatch] = useReducer(reducer, null);
  const [sources, setSources] = useState([]);
  const [reactionPlayer, setReactionPlayer] = useState();
  const [reactionPlayerState, setReactionPlayerState] = useState();

  async function setId(id) {
    dispatch({type: 'setId', id});
  }

  const context = {
    state, dispatch,
    reactionUrl, setReactionUrl,
    sourceUrl, setSourceUrl,
    sources, setSources,
    setId, // really means: save successful, this is your new id
    edit, setEdit,
    reactionPlayer, setReactionPlayer,
    reactionPlayerState, setReactionPlayerState
  };

  return <SequenceContext.Provider value={context} {...props} />;
}

export default Sequence;
