import {Box, Button} from "@mui/material";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import {toggleFullscreen} from "./FullScreenButton";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import YouTube from "react-youtube";
import {useSequence} from "./Sequence";
import {useState} from "react";
import SourceVideo from "./SourceVideo";
import {useResizeDetector} from "react-resize-detector";
import {isActive, isEnded} from "./util";
import {sourceState} from "./Full";

function handleError(e) {
  console.log('error', e);
}

function handlePlaybackRateChange(e) {
  console.log('playbackRateChange', e);
}

function handlePlaybackQualityChange(e) {
  console.log('playbackQualityChange', e);
}

function fit(reaction, width, height) {
  const aw = reaction?.width || 16;
  const ah = reaction?.height || 9;

  let fitWidth, fitHeight, fitLeft, fitTop;
  if (height * aw < width * ah) { // video wider than frame
    fitTop = 0;
    fitHeight = height;
    fitWidth = Math.floor((fitHeight + 0.5) * aw / ah);
    fitLeft = Math.floor((width - fitWidth + 0.5) / 2);
  } else {
    fitLeft = 0;
    fitWidth = width;
    fitHeight = Math.floor((fitWidth + 0.5) * ah / aw);
    fitTop = Math.floor((height - fitHeight + 0.5) / 2);
  }
  return {fitWidth, fitHeight, fitLeft, fitTop};
}

function Player({edit: edit, move}) {
  // hooks
  const {
    state, sources,
    reactionPlayer: player, setReactionPlayer: setPlayer,
    reactionPlayerState: playerState, setReactionPlayerState: setPlayerState
  } = useSequence();
  const [seekCount, setSeekCount] = useState(0);
  const {width, height, ref} = useResizeDetector();

  // computed
  const videoId = state.reaction?.videoId;
  const {fitWidth, fitHeight, fitLeft, fitTop} = fit(state.reaction, width, height);

  function handleReady(e) {
    console.log('reaction ready', e);
    setPlayer(e.target);
  }

  function handlePlay(e) {
    // console.log('play', e); // commented log to reduce synchronization delay
    sources.forEach(s => s.handlePlay(e));
  }

  function handlePause(e) {
    console.log('pause', e);
    sources.forEach(s => s.handlePause(e));
  }

  function handleEnd(e) {
    console.log('end', e);
    sources.forEach(s => s.handleEnd(e));
  }

  function handleStateChange(e) {
    console.log('stateChange', e);

    setPlayerState(e.data);

    setSeekCount(sc => sc + 1); // triggers forced synchronization on stop
  }

  function handleClick(e) {
    if (player.getPlayerState() === 2) {
      const now = player.getCurrentTime();
      sources.forEach(data => {
        const source = state.sources.find(s => s.id === data.id);
        const ss = sourceState(source, now);
        if (ss.play) data.player.playVideo();
      });
      player.playVideo();
    } else {
      sources.forEach(source => {
          try {
            source.player?.pauseVideo()
          } catch (e) {
            console.log(e)
          }
        }
      );
      player.pauseVideo();
    }
  }

  return <Box
    display="flex"
    justifyContent="center"
    // alignItems="center"
    minHeight="100vh"
    flexGrow="1"
    alignItems="stretch">
    <Box ref={ref} className="full" style={{flexGrow: "1"}} m={2}>
      {isActive(playerState) &&
        <Box sx={{
          bottom: 0, left: 12, position: "absolute", zIndex: 100000000, backgroundColor: "black",
          clip: "rect(5px, 46px, 40px, 0px)", overflow: "hidden"
        }}>
          <Button sx={{
            minWidth: 46, width: 46
          }} size="small" color="primary" onClick={handleClick}>{playerState !== 1 ?
            <PlayArrowRoundedIcon sx={{fontSize: 32}}/> :
            <PauseRoundedIcon sx={{fontSize: 32}}/>}</Button>
        </Box>}


      {(isActive(playerState) || isEnded(playerState)) &&
        <Box sx={{
          bottom: 0, right: 12, position: "absolute", zIndex: 100000000, backgroundColor: "black",
          clip: "rect(5px, 46px, 40px, 0px)", overflow: "hidden"
        }}>
          <Button
            color="primary"
            size="small"
            aria-keyshortcuts="f"
            onClick={toggleFullscreen}
            sx={{minWidth: 40, width: 40}}>{
            document.fullscreenElement ?
              <FullscreenExitIcon sx={{fontSize: 32}}/> :
              <FullscreenIcon sx={{fontSize: 32}}/>
          }</Button></Box>}

      {videoId &&
        <YouTube className="reaction focus"
                 videoId={videoId}
                 onReady={handleReady}
                 onPlay={handlePlay}
                 onPause={handlePause}
                 onEnd={handleEnd}
                 onError={handleError}
                 onStateChange={handleStateChange}
                 onPlaybackRateChange={handlePlaybackRateChange}
                 onPlaybackQualityChange={handlePlaybackQualityChange}
                 opts={{playerVars: {fs: 1, rel: 0, modestbranding: 0, autoplay: edit ? 0 : 1}}}
        />}

      {player &&
        // when the reaction changes, wipe the source state too
        <div
          style={{
            position: "absolute",
            pointerEvents: "none",
            width: fitWidth || "auto",
            height: fitHeight || "auto",
            left: fitLeft || "auto",
            top: fitTop || "auto"
          }}>

          {state.sources.map(s =>
            <SourceVideo key={s.id} source={s} edit={edit} move={move}
                         seekCount={seekCount} fitWidth={fitWidth} fitHeight={fitHeight}/>)}

        </div>}

    </Box>
  </Box>;
}

export default Player;
