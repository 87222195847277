function pushState(path) {
  if (path !== window.location.pathname) window.history.pushState("", "", path);
}

export function editHash(id) {
  pushState(id ? `/edit/${id}` : '/edit');
}

export function watchHash(id) {
  pushState(id ? `/${id}` : '/');
}
