import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField
} from "@mui/material";
import {LoadingButton} from '@mui/lab';
import {feedback} from "./api";
import FeedbackIcon from '@mui/icons-material/Feedback';
import {useEffect, useState} from "react";
import isEmail from "validator/es/lib/isEmail";

const Feedback = ({subject}) => {
  // hooks
  const [open, setOpen] = useState(false);
  const [name, setName] = useState({});
  const [email, setEmail] = useState({});
  const [body, setBody] = useState({});
  const [state, setState] = useState(0);

  // computed
  const disabled = Boolean(state);

  useEffect(() => {
    if (!open) {
      setName({});
      setEmail({});
      setBody({});
      setState(0);
    }
  }, [open]);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  async function sendFeedback() {
    let error = false;
    if (!name.value) {
      setName({...name, error: 1});
      error = true;
    }
    if (email.value && !isEmail(email.value)) {
      setEmail({...email, error: 1});
      error = true;
    }
    if (!body.value) {
      setBody({...body, error: 1});
      error = true;
    }
    if (error) return;

    setState(1);
    try {
      const response = await feedback(`${subject || 'Feedback'}, from ${name.value}`, body.value, {
        name: name.value,
        email: email.value
      });
      if (response.data.message === 'success') {
        setState(2);
      } else {
        setState(3);
      }
    } catch (e) {
      setState(3);
    }
  }

  return <Box>
    <Button onClick={handleClickOpen}>Give Feedback</Button>
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>Send feedback</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <DialogContentText>
            Thanks for using the r<span>ea</span>ct<span>io</span>ns player, we'd love to know what you think.<br/>
            If you have any comments or suggestions, please fill out the form below.
          </DialogContentText>
          <TextField autoFocus label="Name" value={name.value} onChange={e => setName({value: e.target.value})}
                     required disabled={disabled}
                     error={name.error} helperText={name.error ? 'Please provide a name' : ' '}/>
          <TextField label="Email" type="email" value={email.value} onChange={e => setEmail({value: e.target.value})}
                     disabled={disabled}
                     error={email.error} helperText={email.error ? 'Please provide a valid email address' : ' '}/>

          <TextField label="Comments" value={body.value} minRows="10" maxRows="10"
                     onChange={e => setBody({value: e.target.value})}
                     required multiline disabled={disabled} error={body.error}
                     helperText={body.error ? 'Please provide a comment' : ' '}/>

          {state === 2 && <Alert variant="outlined" severity="success">Feedback submitted, thanks!</Alert>}
          {state === 3 &&
            <Alert variant="outlined" severity="error">There was a problem sending feedback, sorry</Alert>}
        </Stack>
      </DialogContent>
      <DialogActions>
        {state < 2 && <Button onClick={handleClose}>Cancel</Button>}
        {state < 2 && <LoadingButton
          size="small"
          onClick={sendFeedback}
          endIcon={<FeedbackIcon/>}
          loading={state === 1}
          loadingPosition="end"
          variant="contained"
          disabled={state === 2}>Send</LoadingButton>}
        {state >= 2 && <Button variant="contained" onClick={handleClose}>Close</Button>}
      </DialogActions>
    </Dialog>
  </Box>
}

export default Feedback;
