import './App.css';
import Full from "./Full";
import {useSequence} from "./Sequence";
import {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {isEditMode} from "./util";
import Home from "./Home";
import Watch from "./Watch";

export async function getJson(jsonfile) {
  console.log('getJson', jsonfile);
  const response = await fetch(jsonfile);
  return await response.json();
}

const DATA = 'https://rctns.com/data/prod';

const CHANNEL_VIDEO = /\/c\/([^/]+)\/([^/]+)/;
const EDIT_PATH = `/edit`;
const WATCH_PATH = `/watch`;
const SHA1_HASH = /\/([a-z0-9]{40})/;

function App() {
  const {state, dispatch, edit, setEdit} = useSequence();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const stateVersionId = state?.version?.id;
  const stateVideoId = state?.reaction?.videoId;

  function locationListener() {
    (async function () {
      const url = new URL(window.location.href);
      let hash, videoId;

      // /c/ChannelName/videoId
      const channelVideo = CHANNEL_VIDEO.exec(url.pathname);
      if (channelVideo) {
        const [, channel, id] = channelVideo;
        videoId = id;
      }

      // /watch
      if (url.pathname.startsWith(WATCH_PATH)) {
        let v = url.searchParams.get('v') || url.pathname.substring(WATCH_PATH.length + 1);
        if (v && v.length < 40) videoId = v;
      }

      // /edit
      if (url.pathname.startsWith(EDIT_PATH)) {
        let v = url.searchParams.get('v') || url.pathname.substring(EDIT_PATH.length + 1);
        if (v && v.length < 40) videoId = v;
      }

      const sha1Hash = SHA1_HASH.exec(url.pathname);
      if (sha1Hash) {
        const [, id] = sha1Hash;
        hash = id;
      }

      const ed = Boolean(isEditMode())
      if (ed !== edit) setEdit(ed);

      async function loadJson(path) {
        setLoading(true);
        try {
          const load = await getJson(path);
          dispatch({type: 'load', load});
        } catch (e) {
          setError(e);
        }
        setLoading(false);
      }

      if (videoId) {
        await loadJson(`${DATA}/youtube/${videoId}`);
        return;
      }
      if (hash) {
        if (hash === stateVersionId) {
          // already loaded
          return;
        }
        await loadJson(`${DATA}/reaction/${hash}`);
        return;
      }
    })().catch(console.error);
  }

  // init location
  useEffect(locationListener, []);
  // observe location changes
  useEffect(() => {
    window.addEventListener('popstate', locationListener);
    return () => window.removeEventListener('popstate', locationListener);
  }, [edit, stateVersionId, stateVideoId]);

  let content;
  if (error) {
    content = <Box>
      load error: {error.message}
    </Box>;
  } else {
    if (state) {
      if (edit) {
        content = <Full/>;
      } else {
        content = <Watch/>;
      }
    } else {
      if (loading) {
        content = <div>loading</div>;
      } else {
        content = <Home/>;
      }
    }
  }

  return (
    <div style={{margin: 'auto'}}>{content}</div>
  );
}

export default App;
