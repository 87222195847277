import {v4 as uuidv4} from 'uuid';


export function inflate(state) {
  state.sources.forEach(s => s.id = uuidv4());
  state.sources.flatMap(s => s.commands).forEach(c => c.id = uuidv4());
  return state;
}

export function deflate(s) {
  let state = JSON.parse(JSON.stringify(s));
  state.sources.forEach(s => delete s.id);
  state.sources.flatMap(s => s.commands).forEach(c => delete c.id);
  return state;
}


export function replaceOrAppend(list, predicate, item) {
  const existing = list.findIndex(predicate);
  if (existing === -1) {
    list.push(item);
  } else {
    list[existing] = item;
  }
  return list;
}


export function isEditMode() {
  return window.location.pathname.includes('/edit');
}


export function newSource(videoId) {
  return {videoId, show: 1, left: 0.25, top: 0.25, width: 0.5, height: 0.5, commands: []};
}

export function newReaction(videoId, sourceVideoId) {
  const state = {reaction: {videoId}, sources: []};
  if (sourceVideoId) state.sources.push(newSource(sourceVideoId));
  return state;
}

export function getPathPrefix() {
  return window.location.origin;
}

export function getCanonicalUrlForHash(hash) {
  return `${getPathPrefix()}/${hash}`;
}

export function isActive(video) {
  if (video === 1 || video === 2 || video === 3) return true;
  const s = video?.getPlayerState?.();
  return s === 1 || s === 2 || s === 3;
}

export function isEnded(video) {
  if (video === 0) return true;
  return video?.getPlayerState?.() === 0;
}

export function moveSource(sourceBox, state, width, height) {
  sourceBox.style.left = Math.floor(state.left * width + 0.5) + 'px';
  sourceBox.style.top = Math.floor(state.top * height + 0.5) + 'px';
  sourceBox.style.width = Math.floor(state.width * width + 0.5) + 'px';
  sourceBox.style.height = Math.floor(state.height * height + 0.5) + 'px';
  if (state.show) {
    sourceBox.classList.remove('hide');
  } else {
    sourceBox.classList.add('hide');
  }
}
