import {useSequence} from "./Sequence";
import {useEffect, useState} from "react";
import {Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import FastForwardRoundedIcon from '@mui/icons-material/FastForwardRounded';
import FastRewindRoundedIcon from '@mui/icons-material/FastRewindRounded';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ControlCameraRoundedIcon from '@mui/icons-material/ControlCameraRounded';
import {sourceState} from "./Full";

const DP = 1;

function displayTimestamp(ts) {
  if (ts === undefined) return undefined;
  const hours = Math.floor(ts / 60 / 60);
  ts -= hours * 60 * 60;
  const minutes = Math.floor(ts / 60);
  ts -= minutes * 60;
  const seconds = Math.floor(ts * DP) / DP;
  return `${hours ? `${hours}:` : ''}${minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
}

function isForward(c, i, arr) {
  const before = sourceState({commands: arr.slice(0, i)}, c.ts);
  return before.seek - before.ts <= c.seek - c.ts;
}

const CommandList = ({seek, source}) => {
  const {state, dispatch} = useSequence();
  const {commands} = source;
  const [json, setJson] = useState(JSON.stringify(state, null, 2));

  useEffect(() => {
    setJson(JSON.stringify(state, null, 2));
  }, [state]);

  return <Paper sx={{width: '100%', display: 'flex', flexDirection: 'column', minHeight: 0, flexGrow: 1}}>
    <TableContainer>
      <Table sx={{minWidth: 200}} size="small" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="right">Reaction</TableCell>
            <TableCell>Command</TableCell>
            <TableCell align="right">Source</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {commands
            .map((cmd, i, arr) => <TableRow hover key={cmd.id} onClick={e => seek?.(cmd.ts)}>
              <TableCell align="right">{displayTimestamp(cmd.ts)}</TableCell>
              <TableCell><Chip size="small" icon={
                cmd.type === 'play' ? <PlayArrowRoundedIcon/> :
                  cmd.type === 'pause' ? <PauseRoundedIcon/> :
                    cmd.type === 'seek' ? isForward(cmd, i, arr) ? <FastForwardRoundedIcon/> :
                        <FastRewindRoundedIcon/> :
                      cmd.type === 'show' ? <VisibilityIcon/> :
                        cmd.type === 'hide' ? <VisibilityOffIcon/> :
                          cmd.type === 'move' ? <ControlCameraRoundedIcon/> :
                            null
              } label={cmd.type}/></TableCell>
              <TableCell align="right">{displayTimestamp(cmd.seek)}</TableCell>
              <TableCell><IconButton
                onClick={e => dispatch({type: 'command', command: 'delete', source: source.id, id: cmd.id})}
                size="small"><DeleteRoundedIcon/></IconButton></TableCell>

            </TableRow>)}
        </TableBody>
      </Table>
    </TableContainer>
  </Paper>;
}

export default CommandList;
