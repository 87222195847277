export function dragElement(box, handle, dragEndCallback) {
  let reaction = document.querySelector('.reaction');
  let dx = 0, dy = 0, downX = 0, downY = 0;
  let startW, startH, startX, startY;
  let div = handle ? box.querySelector('.' + handle) : box;
  div.onpointerdown = handleDragStart;

  function handleDragStart(e) {
    e = e || window.event;
    e.preventDefault();
    e.stopPropagation();
    downX = e.clientX - 0;
    downY = e.clientY - 0;
    startX = box.offsetLeft;
    startY = box.offsetTop;
    startW = box.offsetWidth;
    startH = box.offsetHeight;
    document.onpointerup = handleDragEnd;
    document.onpointermove = handleDrag;
    reaction.style.pointerEvents = 'none'; // restrict mouse events to this frame
    box.classList.add('dragging');
  }

  function handleDrag(e) {
    e = e || window.event;
    e.preventDefault();
    dx = Math.floor(e.clientX - downX);
    dy = Math.floor(e.clientY - downY);

    if (handle) {
      if (handle.includes('e')) {
        let width = Math.floor(startW + dx);
        if (width < 320) width = 320;
        if (startX + width > box.parentElement.offsetWidth) width = box.parentElement.offsetWidth - startX;
        box.style.width = width + "px";
      } else {
        let width = Math.floor(startW - dx);
        if (width < 320) width = 320;
        let left = startX - (width - startW);
        if (left < 0) {
          width += left;
          left = 0;
        }
        box.style.width = width + "px";
        box.style.left = left + "px";
      }

      if (handle?.includes('s')) {
        let height = Math.floor(startH + dy);
        if (height < 240) height = 240;
        if (startY + height > box.parentElement.offsetHeight) height = box.parentElement.offsetHeight - startY;
        box.style.height = height + "px";
      } else {
        let height = Math.floor(startH - dy);
        if (height < 240) height = 240;
        let top = startY - (height - startH);
        if (top < 0) {
          height += top;
          top = 0;
        }
        box.style.height = height + "px";
        box.style.top = top + "px";
      }

    } else {
      let left = Math.floor(startX + dx);
      if (left + startW > box.parentElement.offsetWidth) left = box.parentElement.offsetWidth - startW;
      if (left < 0) left = 0;
      box.style.left = left + "px";

      let top = Math.floor(startY + dy);
      if (top + startH > box.parentElement.offsetHeight) top = box.parentElement.offsetHeight - startH;
      if (top < 0) top = 0;
      box.style.top = top + "px";

    }
  }

  function handleDragEnd() {
    // stop moving when mouse button is released:
    document.onpoinerup = null;
    document.onpointermove = null;
    reaction.style.pointerEvents = 'auto';
    box.classList.remove('dragging');
    dragEndCallback?.();
  }
}
