import axios from 'axios';

const endpoint = 'https://api.rctns.com/v1';

export async function feedback(subject, body, fields) {
  const data = {subject, body, fields};
  return axios
    .post(`${endpoint}/feedback`, data);
}

export async function save(state) {
  return axios
    .post(`${endpoint}/reaction`, state);
}

export async function publish(id, videoId) {
  const data = {id, videoId};
  return axios
    .put(`${endpoint}/publish`, data);
}
