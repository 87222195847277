import Player from "./Player";
import {Box} from "@mui/material";

const Watch = () => {
  return <Box>
    <Player/>
  </Box>;
}

export default Watch;
