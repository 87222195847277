import {Box, Button, Stack, Typography} from "@mui/material";
import CommandList from "./CommandList";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import PauseRoundedIcon from "@mui/icons-material/PauseRounded";
import FastRewindRoundedIcon from "@mui/icons-material/FastRewindRounded";
import FastForwardRoundedIcon from "@mui/icons-material/FastForwardRounded";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ControlCameraRoundedIcon from "@mui/icons-material/ControlCameraRounded";
import {useSequence} from "./Sequence";
import {v4 as uuidv4} from 'uuid';
import SyncIcon from '@mui/icons-material/Sync';


const SourceControls = ({reactionPlayer, source, seekTo, ...props}) => {
  const {dispatch, sources} = useSequence();
  const sourcePlayer = sources.find(s => s.id === source.id)?.player;

  function sync() {
    dispatch({
      type: 'sync', source: source.id,
      ts: reactionPlayer.getCurrentTime(),
      seek: sourcePlayer.getCurrentTime()
    });
  }

  async function addCommand(command) {
    dispatch({
      type: 'command',
      command,
      id: uuidv4(),
      source: source.id,
      ts: await reactionPlayer.getCurrentTime(),
      seek: await sourcePlayer.getCurrentTime()
    });
  }

  return <Box display="flex" flexDirection="column" overflow="auto" {...props}>
    <Typography>Source Video</Typography>
    <h2>Command List</h2>
    <CommandList source={source} seek={seekTo}/>
    <Stack direction="column">
      <Stack direction="row" spacing={1}>
        <Button onClick={e => addCommand('play')}><PlayArrowRoundedIcon/> Play</Button>
        <Button onClick={e => addCommand('pause')}><PauseRoundedIcon/> Pause</Button>
        <Button onClick={e => addCommand('resume')}><PlayArrowRoundedIcon/> Resume</Button>
        <Button onClick={e => addCommand('seek')}><FastRewindRoundedIcon/> Seek <FastForwardRoundedIcon/></Button>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Button onClick={e => addCommand('show')}><VisibilityIcon/> Show</Button>
        <Button onClick={e => addCommand('hide')}><VisibilityOffIcon/> Hide</Button>
        <Button onClick={e => addCommand('move')}><ControlCameraRoundedIcon/> Move</Button>
        <Button onClick={e => sync()}><SyncIcon/> Sync</Button>
      </Stack>
    </Stack>
  </Box>;
}

export default SourceControls;
