import {Box} from "@mui/material";
import SelectReaction from "./SelectReaction";
import {useSequence} from "./Sequence";

const Home = () => {
  const {edit} = useSequence();
  return <Box>
    <h1>rctns home</h1>

    {edit && <SelectReaction/>}
  </Box>;
}

export default Home;
