import YouTube from "react-youtube";
import {useCallback, useEffect, useRef, useState} from "react";
import {dragElement} from "./drag";
import {useSequence} from "./Sequence";
import {applyCommand, sourceState} from "./Full";
import {isActive, moveSource, replaceOrAppend} from "./util";


function SourceVideo({source, edit: edit, move, seekCount: seekCount, fitWidth, fitHeight}) {
  // assert Boolean(reaction) === true
  // assert Boolean(sourceData) === true
  // hooks
  const {dispatch, sources, setSources, reactionPlayer, reactionPlayerState} = useSequence();
  const boxRef = useRef();
  const [, setCommandSchedule] = useState([]);
  const [, setPauseState] = useState({});
  const [offset, setOffset] = useState(0);

  // computed
  const box = boxRef.current;
  const player = sources.find(s => s.id === source.id)?.player;
  const active = isActive(reactionPlayerState);

  useEffect(() => {
    if (!box) return;

    function applyMove() {
      const maxWidth = box.parentElement.offsetWidth;
      const maxHeight = box.parentElement.offsetHeight;
      dispatch({
        type: 'move',
        source: source.id,
        ts: reactionPlayer.getCurrentTime(),
        left: parseInt(box.style.left) / maxWidth,
        top: parseInt(box.style.top) / maxHeight,
        width: parseInt(box.style.width) / maxWidth,
        height: parseInt(box.style.height) / maxHeight
      })
    }

    dragElement(box, undefined, applyMove);
    dragElement(box, 'nw', applyMove);
    dragElement(box, 'ne', applyMove);
    dragElement(box, 'sw', applyMove);
    dragElement(box, 'se', applyMove);
  }, [reactionPlayer, box]);


  // move and show/hide, responds to browser resizing
  useEffect(() => {
    if (!box) return;
    const state = sourceState(source, reactionPlayer.getCurrentTime());
    moveSource(box, state, fitWidth, fitHeight);
  }, [reactionPlayer, box, source, fitWidth, fitHeight]);

  function replaceCommandSchedule(schedule) {
    setCommandSchedule(old => {
      old.forEach(clearTimeout);
      return schedule;
    });
  }

  // player passed from onReady event because render.player refers to previous player
  function initCommandSchedule(player, sourceBox) {
    const ts = reactionPlayer.getCurrentTime();
    const schedule = source.commands
      .filter(c => c.ts >= ts)
      .map(command => setTimeout(() => applyCommand(1, player, sourceBox, command, () => moveSource(sourceBox, command, fitWidth, fitHeight), true), (command.ts - ts) * 1000));
    replaceCommandSchedule(schedule);
  }

  // triggered selectively.
  const keepInSync = useCallback(function (player, force) {
    console.log('keepInSync force', force);
    const now = reactionPlayer.getCurrentTime();
    const state = sourceState(source, now);

    applyCommand(reactionPlayer.getPlayerState(), player, box, state, () => moveSource(box, state, fitWidth, fitHeight), force);
  }, [reactionPlayer, source, box, fitWidth, fitHeight]);


  /**
   * WARNING, when setting up player, the handle functions must not reference the component.player, because it's null!
   */
  function setPlayer(player) {
    setSources(list => {
      return [...replaceOrAppend(list, s => s.id === source.id, {
        id: source.id, box, player, handlePlay(e) {
          const state = sourceState(source, reactionPlayer.getCurrentTime());
          if (state.play) player.playVideo();
          initCommandSchedule(player, box);
        }, handlePause(e) {
          replaceCommandSchedule([]);
          player.pauseVideo();

          // sync test
          console.log('sync test', reactionPlayer.getCurrentTime() - player.getCurrentTime());
          setPauseState(sourceState(source, reactionPlayer.getCurrentTime()));

          keepInSync(player, true);
        }, handleEnd(e) {
          replaceCommandSchedule([]);
          player.seekTo(0);
          player.pauseVideo();
        }
      })]
    });
  }

  // remove the player from the context when the component is removed from the dom
  useEffect(() => () => setSources(sources => sources.filter(s => s.id !== source.id)), []);

  useEffect(() => {
    if (!player) return;
    const interval = setInterval(() => {
      const now = reactionPlayer.getCurrentTime();
      const sourceNow = player.getCurrentTime();
      const lag = now - sourceNow;
      const state = sourceState(source, now);
      const expectedLag = state.ts - state.seek;
      const expectedOffset = lag - expectedLag;

      if (1 > expectedOffset && expectedOffset > 0.2) {
        reactionPlayer.seekTo(now); // causes tiny delay in reaction
      }
      if (-1 < expectedOffset && expectedOffset < -0.2) {
        player.seekTo(sourceNow); // causes tiny delay in source
      }

      if (offset !== expectedOffset) {
        setOffset(expectedOffset);
      }

    }, 500);
    return () => clearInterval(interval);
  }, [reactionPlayer, player, source, seekCount]);


  // a seekCount change deliberately triggers a keepInSync operation
  useEffect(() => {
    // if(reaction?.getPlayerState() === 1) return; // dont do this while playing, remind me why again?
    if (!player) return;
    const interval = setTimeout(() => keepInSync(player, false), 500);
    return () => clearTimeout(interval);
  }, [reactionPlayer, player, keepInSync, seekCount]);

  function handleReady(e) {
    console.log('onReady 123', e.target, e);
    setPlayer(e.target);
  }

  let style;
  if (offset === 0) {
    style = {borderLeft: "solid black 1px"};
  } else {
    const ss = sourceState(source, reactionPlayer.getCurrentTime());
    const maxWidth = Math.floor(ss.width * fitWidth + 0.5) / 4;
    if (offset < 0) {
      const w = Math.floor(Math.min(-offset, 1) * maxWidth + 0.5);
      style = {borderRight: "solid black 1px", width: `${w}px`, left: `-${w / 2}px`};
    }
    if (offset > 0) {
      const w = Math.floor(Math.min(offset, 1) * maxWidth + 0.5);
      style = {borderLeft: "solid black 1px", width: `${w}px`, left: `${w / 2}px`};
    }
  }

  return <div ref={boxRef} style={{position: 'absolute', pointerEvents: 'all', display: active ? 'inherit' : 'none'}}>
    {source.videoId && <YouTube
      className={`source focus ${reactionPlayer.getPlayerState() !== 1 ? "pause" : "play"} ${edit ? "edit" : ""} ${move ? " drag" : ""}`}
      videoId={source.videoId}
      onReady={handleReady}
      opts={{playerVars: {fs: 0, rel: 0, modestbranding: 1, autoplay: 0, controls: 1}}}/>}

    <div className="nw"></div>
    <div className="ne"></div>
    <div className="sw"></div>
    <div className="se"></div>
    <div className="move">
      <div className="lag" style={style}></div>
    </div>
  </div>;
}

export default SourceVideo;
